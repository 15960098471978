@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@fortawesome/fontawesome-free/css/fontawesome.css';

@import 'font-awesome/all.min.css';

@import 'flowbite/dist/flowbite.min.css';

@import 'mobius1-selectr/dist/selectr.min.css';

@import '~toastedjs/dist/toasted.min.css';

@import 'sweetalert2/dist/sweetalert2.css';

@import 'other/base.css';
